// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-components-accordion-mdx": () => import("./../../../src/pages/components/accordion.mdx" /* webpackChunkName: "component---src-pages-components-accordion-mdx" */),
  "component---src-pages-components-alerts-mdx": () => import("./../../../src/pages/components/alerts.mdx" /* webpackChunkName: "component---src-pages-components-alerts-mdx" */),
  "component---src-pages-components-badge-mdx": () => import("./../../../src/pages/components/badge.mdx" /* webpackChunkName: "component---src-pages-components-badge-mdx" */),
  "component---src-pages-components-breadcrumb-mdx": () => import("./../../../src/pages/components/breadcrumb.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-mdx" */),
  "component---src-pages-components-button-group-mdx": () => import("./../../../src/pages/components/button-group.mdx" /* webpackChunkName: "component---src-pages-components-button-group-mdx" */),
  "component---src-pages-components-buttons-mdx": () => import("./../../../src/pages/components/buttons.mdx" /* webpackChunkName: "component---src-pages-components-buttons-mdx" */),
  "component---src-pages-components-cards-mdx": () => import("./../../../src/pages/components/cards.mdx" /* webpackChunkName: "component---src-pages-components-cards-mdx" */),
  "component---src-pages-components-carousel-mdx": () => import("./../../../src/pages/components/carousel.mdx" /* webpackChunkName: "component---src-pages-components-carousel-mdx" */),
  "component---src-pages-components-dropdowns-mdx": () => import("./../../../src/pages/components/dropdowns.mdx" /* webpackChunkName: "component---src-pages-components-dropdowns-mdx" */),
  "component---src-pages-components-figures-js": () => import("./../../../src/pages/components/figures.js" /* webpackChunkName: "component---src-pages-components-figures-js" */),
  "component---src-pages-components-forms-js": () => import("./../../../src/pages/components/forms.js" /* webpackChunkName: "component---src-pages-components-forms-js" */),
  "component---src-pages-components-images-js": () => import("./../../../src/pages/components/images.js" /* webpackChunkName: "component---src-pages-components-images-js" */),
  "component---src-pages-components-input-group-js": () => import("./../../../src/pages/components/input-group.js" /* webpackChunkName: "component---src-pages-components-input-group-js" */),
  "component---src-pages-components-jumbotron-js": () => import("./../../../src/pages/components/jumbotron.js" /* webpackChunkName: "component---src-pages-components-jumbotron-js" */),
  "component---src-pages-components-list-group-mdx": () => import("./../../../src/pages/components/list-group.mdx" /* webpackChunkName: "component---src-pages-components-list-group-mdx" */),
  "component---src-pages-components-modal-js": () => import("./../../../src/pages/components/modal.js" /* webpackChunkName: "component---src-pages-components-modal-js" */),
  "component---src-pages-components-navbar-js": () => import("./../../../src/pages/components/navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-components-navs-mdx": () => import("./../../../src/pages/components/navs.mdx" /* webpackChunkName: "component---src-pages-components-navs-mdx" */),
  "component---src-pages-components-overlays-js": () => import("./../../../src/pages/components/overlays.js" /* webpackChunkName: "component---src-pages-components-overlays-js" */),
  "component---src-pages-components-pagination-js": () => import("./../../../src/pages/components/pagination.js" /* webpackChunkName: "component---src-pages-components-pagination-js" */),
  "component---src-pages-components-progress-js": () => import("./../../../src/pages/components/progress.js" /* webpackChunkName: "component---src-pages-components-progress-js" */),
  "component---src-pages-components-spinners-mdx": () => import("./../../../src/pages/components/spinners.mdx" /* webpackChunkName: "component---src-pages-components-spinners-mdx" */),
  "component---src-pages-components-table-js": () => import("./../../../src/pages/components/table.js" /* webpackChunkName: "component---src-pages-components-table-js" */),
  "component---src-pages-components-tabs-js": () => import("./../../../src/pages/components/tabs.js" /* webpackChunkName: "component---src-pages-components-tabs-js" */),
  "component---src-pages-components-toasts-mdx": () => import("./../../../src/pages/components/toasts.mdx" /* webpackChunkName: "component---src-pages-components-toasts-mdx" */),
  "component---src-pages-getting-started-introduction-mdx": () => import("./../../../src/pages/getting-started/introduction.mdx" /* webpackChunkName: "component---src-pages-getting-started-introduction-mdx" */),
  "component---src-pages-getting-started-support-mdx": () => import("./../../../src/pages/getting-started/support.mdx" /* webpackChunkName: "component---src-pages-getting-started-support-mdx" */),
  "component---src-pages-getting-started-theming-js": () => import("./../../../src/pages/getting-started/theming.js" /* webpackChunkName: "component---src-pages-getting-started-theming-js" */),
  "component---src-pages-getting-started-why-react-bootstrap-mdx": () => import("./../../../src/pages/getting-started/why-react-bootstrap.mdx" /* webpackChunkName: "component---src-pages-getting-started-why-react-bootstrap-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-grid-js": () => import("./../../../src/pages/layout/grid.js" /* webpackChunkName: "component---src-pages-layout-grid-js" */),
  "component---src-pages-layout-media-js": () => import("./../../../src/pages/layout/media.js" /* webpackChunkName: "component---src-pages-layout-media-js" */),
  "component---src-pages-migrating-mdx": () => import("./../../../src/pages/migrating.mdx" /* webpackChunkName: "component---src-pages-migrating-mdx" */),
  "component---src-pages-utilities-react-overlays-js": () => import("./../../../src/pages/utilities/react-overlays.js" /* webpackChunkName: "component---src-pages-utilities-react-overlays-js" */),
  "component---src-pages-utilities-responsive-embed-js": () => import("./../../../src/pages/utilities/responsive-embed.js" /* webpackChunkName: "component---src-pages-utilities-responsive-embed-js" */),
  "component---src-pages-utilities-transitions-mdx": () => import("./../../../src/pages/utilities/transitions.mdx" /* webpackChunkName: "component---src-pages-utilities-transitions-mdx" */)
}

